<template>
	<BaseLayout>
		<Toolbar class="w-full bg-primary p-5" @clickLeft="$router.push('/signin')">
			<template v-slot:start>
				<p class="text-white">
					<Icon class="h-5 w-5" icon="angleLeft" />
				</p>
			</template>
			{{ privacyPolicy.title }}
		</Toolbar>
		<div class="flex w-full justify-center bg-primary pb-16 pt-6 iPhone:pb-5 iPhone:pt-2">
			<img src="assets/logo.svg" class="w-52" alt="" loading="lazy" />
		</div>

		<div class="px-5 pt-10">
			<p class="text-center text-xl text-secondary">{{ privacyPolicy.title }}</p>
			<template v-for="(privacyPolicyDescription, index) in privacyPolicy.description" :key="index">
				<p class="text-md py-2 text-secondary">{{ privacyPolicyDescription.secondaryTitle }}</p>
				<template v-for="(privacyPolicyDetail, index) in privacyPolicyDescription.details" :key="index">
					<p>{{ privacyPolicyDetail }}</p>
				</template>
			</template>

			<p class="mb-2 py-2 text-xs">
				<span class="text-primary underline" @click="$router.push('/termsAndConditions')"> {{ tnc.title }}</span>
			</p>
		</div>
	</BaseLayout>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useSharedStore } from '@/store/shared'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
const { config } = storeToRefs(useSharedStore())
const tnc = config.value.tnc[locale.value]
const privacyPolicy = config.value.privacyPolicy[locale.value]
</script>
